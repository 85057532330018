@font-face {
  font-family: 'ProximaSoft-Regular';
  src: local('ProximaSoft-Regular'), url('./fonts/ProximaSoft-Regular.ttf') format('truetype');
}

html, body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'ProximaSoft-Regular', "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background: #B0B5C4;
  color: #080808;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  width: 100%;
  height: 100%;
}

.site-header {
  position: sticky;
  top: 0;
  z-index: 999;
}

p {
  margin: 0px;
}